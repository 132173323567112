<template>
  <!-- start page title -->
  <div>
  <div class="container">
    <div class="row">
      <div class="col-12 px-0">
        <h2 class="text-primary font-style-2 display-6 text-center mb-5 img-fluid fw-semibold" data-aos="fade-up">{{ $t('specialised_services') }}</h2>
        <img :src="services[parseInt(activeOwlIndex-1)].images" :alt="services[activeOwlIndex-1].title" class="img-fluid">
      </div>
    </div>
  </div>
  <div class="services mb-3" data-aos="fade-up">
      <carousel ref="myCarousel" :center="true" :items="itemsVariable" :loop="true" :navText="[]" :dots="false" @changed="changed" @updated="updated" v-model="activeOwlIndex">

        <div v-for="(value, index) in services" :key="index" class="p-3">
          <div class="card-custom p-3 text-center w-100 mb-0 ">
              <div class="row justify-content-center my-3 ">
                <div class="col-10">
                      <h2 class="font-style-2 text-primary">{{ $t(value.title) }} </h2>
                      <p class="font-size-14">{{ $t(value.content) }}</p>
                      <router-link class="btn btn-primary" :to="value.link" @click="clicked(index)">{{ $t('learn_more2') }}</router-link>
                </div>
              </div>
              </div>
        </div>
      </carousel>
  </div>
  <div class="other-services bg-primary text-white py-3">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-12">
          <h2 class="text-white font-style-2 fw-semibold display-6 text-center my-4 img-fluid" data-aos="fade-up"> {{ $t('other_services') }} </h2>
        </div>
        <div class="col-lg-12 col-11 my-3">
          <div class="column-count font-style-2" data-aos="fade-up">
            <div v-for="(value, index) in otherServices" :key="index">
              <router-link :to="`/services/${index}/${convertToSlug(value.title)}`" class="text-white d-flex fw-normal mb-3 font-size-16">
                <span>{{  ('0' +( index+1)).slice(-2) }}. </span> 
                <span class="ms-2 text-capitalize">
                  {{ $t(value.title) }} </span> 
                <span class="ms-auto me-3"><i class="mdi mdi-chevron-double-right "></i></span> 
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <!-- end page title -->
</template>
<script>
import appConfig from "@/app.config";
import carousel from 'vue-owl-carousel2'
export default {
  components: {  carousel},
  props: {
    
  },
  watch:{
  },
  data() {
    return {
      title: appConfig.title,
      itemsVariable:1,
      activeOwlIndex:1,
      centerWidth:null,
      services:[
        {
          images: require("@/assets/images/app/colonoscopy.jpg"),
          title:"colonoscopy",
          content:"colonoscopy_desc",
          link:"/services/2/endoscopy"
        },
        {
          images: require("@/assets/images/app/haemorrhoids.jpg"),
          title:"haemorrhoids",
          content:"haemorrhoids_desc",
          link:"/services/3/haemorrhoid-surgery"
        },
        {
          images: require("@/assets/images/app/hernia.jpg"),
          title:"hernia",
          content:"hernia_desc",
          link:"/services/9/general-surgery"
        },
        {
          images: require("@/assets/images/app/colorectal-cancer.jpg"),
          title:"colorectal_cancer",
          content:"colorectal_cancer_desc",
          link:"/services/1/colon-polyps-and-cancers"
        },
        {
          images: require("@/assets/images/app/colonoscopy.jpg"),
          title:"colonoscopy",
          content:"colonoscopy_desc",
          link:"/services/2/endoscopy"
        },
        {
          images: require("@/assets/images/app/haemorrhoids.jpg"),
          title:"haemorrhoids",
          content:"haemorrhoids_desc",
          link:"/services/3/haemorrhoid-surgery"
        },
        {
          images: require("@/assets/images/app/hernia.jpg"),
          title:"hernia",
          content:"hernia_desc",
          link:"/services/9/general-surgery"
        },
        {
          images: require("@/assets/images/app/colorectal-cancer.jpg"),
          title:"colorectal_cancer",
          content:"colorectal_cancer_desc",
          link:"/services/1/colon-polyps-and-cancers"
        }
      ],
      otherServices:[
          {
              title: "rectal_polyps_and_cancers",
              path: "/",
            },
            {
              title: "colon_polyps_and_cancers",
              path: "/"
            },
            {
              title: "roboti_surgery",
              path: "/"
            },
            {
              title: "endoscopy",
              path: "/"
            },
            {
              title: "haemorrhoid_surgery",
              path: "/"
            },
            {
              title: "anal_fistula",
              path: "/"
            },
            {
              title: "anal_fissure",
              path: "/"
            },
            {
              title: "laser_surgery",
              path: "/"
            },
            {
              title: "rectal_prolapse",
              path: "/"
            },
            {
              title: "creation_and_closure_of_stoma",
              path: "/"
            },
            {
              title: "peritoneal_cancer",
              path: "/"
            },
            {
              title: "general_surgery",
              path: "/"
            }
      ]
    }
  },
  mounted(){
    const centerItem = document.querySelector(".owl-item.center");
    this.centerWidth = centerItem.offsetWidth;
    const owlNav = document.querySelector(".owl-theme .owl-nav");
    if (owlNav) {
      owlNav.style.width = `${this.centerWidth}px`;
    }
  },
  created(){
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  methods:{
    convertToSlug(str) {
      // Replace all spaces with dashes
      str = str.replace(/\s+/g, '-');
      // Convert all characters to lowercase
      str = str.toLowerCase();
      return str;
    },
    changed(e){
      console.log(e.item)
      // const element = e.target; // get the element that triggered the event
      // console.log(element)
      // element.classList.add('animate__animated'); // add the new class to the element
      // element.classList.add('animate__zoomIn')
      let activeOwlIndex = e.item.index - Math.floor(e.item.count / 2);
      if (activeOwlIndex < 0) {
        activeOwlIndex = e.item.count + activeOwlIndex;
      }
      this.activeOwlIndex = activeOwlIndex; // setting the current active owl index to the v-model


      const centerItem = e.target.querySelector(".owl-item.center");
      this.centerWidth = centerItem.offsetWidth;

    },
    clicked(value){
      console.log(value)
    },
    updated(e) {
      console.log("g"+e)
    },
    handleResize() {
      const width = window.innerWidth;
      console.log(width)
      if (width < 800) {
        this.itemsVariable = 1;
      } else {
        this.itemsVariable = 3;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
<style scoped>
.card-custom{
  border-radius: 20px;
  box-shadow: 2px 5px 13px rgba(0, 0, 0, 0.2);
}
</style>
<style>

.owl-theme .owl-nav{
  position: relative;
  top: 35%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  height: 100%;
  left: 50%;
  max-width: 1140px;
  width:auto;
  z-index: 3;
  background: none;
}
.owl-theme .owl-nav [class*='owl-']{
  background: none !important;
  background-color: none !important;
  background-repeat: no-repeat ;
  background-position: center center !important;
  background-size: contain !important;
  position: absolute;
  width: 50px;
  height: 50px;
  background-size: contain;
  top: 0%;
  -webkit-transform: translateY(-320%);
  -moz-transform: translateY(-320%);
  transform:translateY(-320%);
}
.owl-theme .owl-nav [class*='owl-']:hover{
  background: none;
}
.owl-theme .owl-nav .owl-prev{
  background-image: url(../../../../assets/images/app/prev.png) !important;
  background-color: none !important;
  background-repeat: no-repeat ;
  background-position: center center !important;
  background-size: contain !important;
  left: 0px !important;
}
.owl-theme .owl-nav .owl-next{
  background-image: url(../../../../assets/images/app/next.png) !important;
  background-color: none !important;
  background-repeat: no-repeat ;
  background-position: center center !important;
  background-size: contain !important;
  right: 0px !important;
}
.services{
  overflow: hidden;
  margin-left: -25%;
  width: 150%;
}
.services .owl-item{
  opacity: 0.5;
  transform: scale(0.85);
  transition: all 0.4s ease-in;
}
.services .owl-item.active.center{
  opacity: 1;
  z-index: 3;
  transform: scale(1);
}
.card-custom h2{
  font-size: 28px;
  font-weight: 700;
}
.column-count{
  column-count: 2;
  column-gap: 40px;
}
@media (max-width: 800px){
  .owl-theme .owl-nav [class*='owl-']{
  -webkit-transform: translateY(-370%);
  -moz-transform: translateY(-370%);
  transform:translateY(-370%);
}
  .owl-theme .owl-nav{
    width: 100%;
  }
  .services{
    margin-left: 0%;
    width: 100%;
  }
  .column-count{
      column-count: 1;
      column-gap: 0px;
    }
}
</style>

