<template>
  <!-- start page title -->
  <div>
    <div class="container-fluid p-0">
      <div class=" h-100 w-100" >
        <div class="enquiry_cover position-relative" :style="'background-image: url(' + banner +')'">
        <div class="position-absolute top-0 start-0 bg-overlay w-100 h-100"></div>
        <div class="container h-100 text-white px-4 py-5">
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 col-11">
              <h2 class="text-white display-6 fw-semibold animate__animated animate__slideInRight font-style-2 text-shadow" v-html="$t('your_care_is_our_priority')"></h2>
              <p class="animate__animated animate__fadeInDown mb-2 mb-lg-3 text-shadow" v-html="$t('thankyouchoosing')"></p>
            </div>
            <div class="col-lg-6 col-12 d-none d-lg-block">
                <div class="card card-custom p-3 mb-0 text-body animate__animated animate__slideInRight" >
                  <div class="p-3">
                  <div class="form-group mb-3">
                    <label for="name" class="form-label">{{$t('name')}}</label>
                    <input type="text" class="form-control" id="name"  :placeholder="$t('name')"
                    v-model.trim="formData.name"
                    :class="{
                      'is-invalid': submitted && $v.formData.name.$error,
                    }"
                    >
                    <div
                        v-if="submitted && $v.formData.name.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.formData.name.required"
                          >{{$t('name')}}{{$t('is_required')}}</span
                        >
                    </div>
                  </div>
                  <div class="form-group mb-3">
                    <label for="contact" class="form-label">{{$t('contact')}} </label>
                    <input type="tel" class="form-control" id="contact" placeholder="60xxxxxxxxxx"  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\+\d{1,2})\s?(\d{3})\s?(\d{4})/, '$1$2$3')"
                    v-model.trim="formData.phone"
                    :class="{
                      'is-invalid': submitted && $v.formData.phone.$error,
                    }"
                    >
                      <div
                        v-if="submitted && $v.formData.phone.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.formData.phone.required"
                          >{{$t('phone')}}{{$t('is_required')}}</span
                        >
                        <span v-if="!$v.formData.phone.countryCodeAndPhone">
                          {{$t('phone_invalid')}}
                        </span>
                      
                      </div>
                  </div>
                  <div class="form-group mb-3">
                    <label for="email" class="form-label">{{$t('email')}} </label>
                    <input type="email" class="form-control bg-white" id="email" :placeholder="$t('email')"
                    v-model.trim="formData.email"
                    :class="{
                      'is-invalid': submitted && $v.formData.email.$error,
                    }"
                    >
                    <div
                        v-if="submitted && $v.formData.email.$error"
                        class="invalid-feedback"
                      >
                      <span v-if="!$v.formData.email.required"
                        >{{$t('email')}}{{$t('is_required')}}</span
                      >
                      <span v-if="!$v.formData.email.email">
                        {{$t('email_invalid')}}
                      </span>
                    </div>
                  </div>
                  <div class="form-group mb-3">
                    <label for="message" class="form-label">{{$t('message')}}</label>
                    <textarea class="form-control" id="message" rows="4"
                    v-model.trim="formData.enquiry"
                    :placeholder="$t('message')"
                    :class="{
                      'is-invalid': submitted && $v.formData.enquiry.$error,
                    }"
                    ></textarea>
                    <div
                        v-if="submitted && $v.formData.enquiry.$error"
                        class="invalid-feedback"
                      >
                      <span v-if="!$v.formData.enquiry.required"
                        >{{$t('message')}}{{$t('is_required')}}</span
                      >
                    </div>
                  </div>
                  <div class="form-group mb-3">
                    <label for="quest" class="form-label">{{mathQuestion}}</label>
                    <input type="tel" class="form-control" id="quest" :placeholder="$t('answer')"
                    v-model.trim="formData.mathAnswer" oninput="this.value = this.value.replace(/[^+-\d]/g, '').replace(/(\+?[\d-]{1,2})\s?(\d{3})\s?(\d{4})/, '$1$2$3')"
                    :class="{
                      'is-invalid': submitted && $v.formData.mathAnswer.$error,
                    }"
                    >
                    <div
                        v-if="submitted && $v.formData.mathAnswer.$error"
                        class="invalid-feedback"
                      >
                      <span v-if="!$v.formData.mathAnswer.required"
                        >{{$t('anwser_required')}}</span
                      >
                      <span v-else-if="!$v.formData.mathAnswer.isCorrect"
                        >{{$t('wrong_anwser')}}</span
                      >
                    </div>

                  </div>
                  <div class="mb-0">
                    <button class="btn btn-primary fw-medium" :disabled="loading" @click="submit" type="button">
                    <span v-if="loading">{{$t('submitting')}}</span>
                    <span v-else> {{$t('Submit')}}</span>
                    <span class="spinner-border spinner-border-sm ms-2" v-if="loading" role="status"
                      aria-hidden="true"></span>
                    </button>
                  </div>
                  </div>
                </div>
            </div>
           
          </div>
        </div>
        </div>
      </div>
    </div>
    <div class="bg-primary">
    <div class="container d-block d-lg-none pb-2 enquiry_box">
      <div class="row align-items-center justify-content-center">
        <div class="col-12 px-4 px-md-3">
          <div class="card card-custom p-3 mb-0 text-body">
            <div class="p-3">
                  <div class="form-group mb-3">
                    <label for="name" class="form-label">{{$t('name')}}</label>
                    <input type="text" class="form-control" id="name"  :placeholder="$t('name')"
                    v-model.trim="formData.name"
                    :class="{
                      'is-invalid': submitted && $v.formData.name.$error,
                    }"
                    >
                    <div
                        v-if="submitted && $v.formData.name.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.formData.name.required"
                          >{{$t('name')}}{{$t('is_required')}}</span
                        >
                    </div>
                  </div>
                  <div class="form-group mb-3">
                    <label for="contact" class="form-label">{{$t('contact')}} </label>
                    <input type="tel" class="form-control" id="contact" placeholder="60xxxxxxxxxx"  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\+\d{1,2})\s?(\d{3})\s?(\d{4})/, '$1$2$3')"
                    v-model.trim="formData.phone"
                    :class="{
                      'is-invalid': submitted && $v.formData.phone.$error,
                    }"
                    >
                      <div
                        v-if="submitted && $v.formData.phone.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.formData.phone.required"
                          >{{$t('phone')}}{{$t('is_required')}}</span
                        >
                        <span v-if="!$v.formData.phone.countryCodeAndPhone">
                          {{$t('phone_invalid')}}
                        </span>
                      
                      </div>
                  </div>
                  <div class="form-group mb-3">
                    <label for="email" class="form-label">{{$t('email')}} </label>
                    <input type="email" class="form-control bg-white" id="email" :placeholder="$t('email')"
                    v-model.trim="formData.email"
                    :class="{
                      'is-invalid': submitted && $v.formData.email.$error,
                    }"
                    >
                    <div
                        v-if="submitted && $v.formData.email.$error"
                        class="invalid-feedback"
                      >
                      <span v-if="!$v.formData.email.required"
                        >{{$t('email')}}{{$t('is_required')}}</span
                      >
                      <span v-if="!$v.formData.email.email">
                        {{$t('email_invalid')}}
                      </span>
                    </div>
                  </div>
                  <div class="form-group mb-3">
                    <label for="message" class="form-label">{{$t('message')}}</label>
                    <textarea class="form-control" id="message" rows="4"
                    v-model.trim="formData.enquiry"
                    :placeholder="$t('message')"
                    :class="{
                      'is-invalid': submitted && $v.formData.enquiry.$error,
                    }"
                    ></textarea>
                    <div
                        v-if="submitted && $v.formData.enquiry.$error"
                        class="invalid-feedback"
                      >
                      <span v-if="!$v.formData.enquiry.required"
                        >{{$t('message')}} {{$t('is_required')}}</span
                      >
                    </div>
                  </div>
                  <div class="form-group mb-3">
                    <label for="quest" class="form-label">{{mathQuestion}}</label>
                    <input type="tel" class="form-control" id="quest" :placeholder="$t('answer')"
                    v-model.trim="formData.mathAnswer" oninput="this.value = this.value.replace(/[^+-\d]/g, '').replace(/(\+?[\d-]{1,2})\s?(\d{3})\s?(\d{4})/, '$1$2$3')"
                    :class="{
                      'is-invalid': submitted && $v.formData.mathAnswer.$error,
                    }"
                    >
                    <div
                        v-if="submitted && $v.formData.mathAnswer.$error"
                        class="invalid-feedback"
                      >
                      <span v-if="!$v.formData.mathAnswer.required"
                        >{{$t('anwser_required')}}</span
                      >
                      <span v-else-if="!$v.formData.mathAnswer.isCorrect"
                        >{{$t('wrong_anwser')}}</span
                      >
                    </div>

                  </div>
                  <div class="mb-0">
                    <button class="btn btn-primary fw-medium" :disabled="loading" @click="submit" type="button">
                    <span v-if="loading">{{$t('submitting')}}</span>
                    <span v-else> {{$t('Submit')}}</span>
                    <span class="spinner-border spinner-border-sm ms-2" v-if="loading" role="status"
                      aria-hidden="true"></span>
                    </button>
                  </div>
                  </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
  <!-- end page title -->
</template>
<script>
import appConfig from "@/app.config";
import { required, minLength, email, helpers} from "vuelidate/lib/validators";
export const countryCodeAndPhone = (value) => {
  const regex = /^\d{8,15}?$/
  return helpers.regex('countryCodeAndPhone', regex)(value)
}
import axios from "axios";
import Swal from "sweetalert2";

export default {
  components: {  },
  props: {
    
  },
  watch:{
  },
  data() {
    return {
      title: appConfig.title,
      banner:  require("@/assets/images/app/enquiry-bg.jpg"),
      submitted:false,
      loading:false,
      mathQuestion: null,
      answer:null,
      formData:{
        name:"",
        email:"",
        phone:"",
        enquiry:"",
        mathAnswer:null
      },
    }
  },
  validations: {
    formData:{
      name: {
        required
      },
      email: {
        required,
        email
      },
      phone: {
        required,
        countryCodeAndPhone,
        minLength: minLength(10)
      },
      enquiry: {
        required,
      },
      mathAnswer: {
      required,
      isCorrect(value) {
        return Number(value) === this.answer;
      }
    }
    }
  },
  mounted(){
  },
  created(){
    this.generateMathQuestion();
  },
  methods:{
    generateMathQuestion() {
      const num1 = Math.floor(Math.random() * 10) + 1;
      const num2 = Math.floor(Math.random() * 10) + 1;
      const operators = ['+', '-', '*'];
      const operator = operators[Math.floor(Math.random() * operators.length)];
      const question = `${num1} ${operator} ${num2} = ?`;
      let answer;
      switch (operator) {
        case '+':
          answer = num1 + num2;
          break;
        case '-':
          answer = num1 - num2;
          break;
        case '*':
          answer = num1 * num2;
          break;
        case '/':
          answer = num1 / num2;
          break;
      }
      this.mathQuestion = question;
      this.answer = answer;
    },
    submit(){
      this.submitted = true
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
          this.$Progress.start();
          this.loading = true
          var bodyFormData = new FormData();
          bodyFormData.append("name",this.formData.name);
          bodyFormData.append("email",this.formData.email);
          bodyFormData.append("phone",this.formData.phone);
          bodyFormData.append("enquiry",this.formData.enquiry);
          axios({
              method: "post",
              url: "https://controller.limsurgery.com/controller/mail/submitEnquiry",
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                this.formData={
                  name:"",
                  email:"",
                  phone:"",
                  enquiry:"",
                  mathAnswer:null
                },
                Swal.fire(
                  {
                  icon: 'success',
                  title: this.$t('success'),
                  html:  this.$t('successMsg'),
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('close'),
                })
              } else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: this.$t('Oops'),
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('close'),
                  })
                  this.$Progress.fail();
              }
              this.loading = false;
              this.submitted = false;
              this.$Progress.finish()
              this.generateMathQuestion();
          })
          .catch((error)=> {
              this.loading = false;
              this.submitted = false;
              this.$Progress.finish();
              this.generateMathQuestion();
              Swal.fire(
                {
                icon: 'error',
                title: this.$t('Oops'),
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('done'),
              })
        });
      }
    }
  },
  beforeDestroy() {
    
  }
};
</script>
<style scoped>
.text-shadow{
  text-shadow: 2px 5px 13px rgba(0, 0, 0, 0.2);
}
.card-custom{
  border-radius: 20px;
  box-shadow: 2px 5px 13px rgba(0, 0, 0, 0.2);
}
.enquiry_cover{
  background-size: cover ;
  background-position: center center;
  background-repeat: no-repeat;
}
.enquiry_box{
  top:-30px;
  position: relative;
}
@media (max-width: 800px){
  .top-custom{
    top:20%
  }
  .banner h2{
    font-size: 24px;
  }
  .banner p{
    font-size: 13px;
  }

  .banner h1{
    font-size: 22px;
  }
}
</style>