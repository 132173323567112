var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"h-100 w-100"},[_c('div',{staticClass:"enquiry_cover position-relative",style:('background-image: url(' + _vm.banner +')')},[_c('div',{staticClass:"position-absolute top-0 start-0 bg-overlay w-100 h-100"}),_c('div',{staticClass:"container h-100 text-white px-4 py-5"},[_c('div',{staticClass:"row align-items-center justify-content-center"},[_c('div',{staticClass:"col-lg-6 col-11"},[_c('h2',{staticClass:"text-white display-6 fw-semibold animate__animated animate__slideInRight font-style-2 text-shadow",domProps:{"innerHTML":_vm._s(_vm.$t('your_care_is_our_priority'))}}),_c('p',{staticClass:"animate__animated animate__fadeInDown mb-2 mb-lg-3 text-shadow",domProps:{"innerHTML":_vm._s(_vm.$t('thankyouchoosing'))}})]),_c('div',{staticClass:"col-lg-6 col-12 d-none d-lg-block"},[_c('div',{staticClass:"card card-custom p-3 mb-0 text-body animate__animated animate__slideInRight"},[_c('div',{staticClass:"p-3"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t('name')))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.formData.name),expression:"formData.name",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.formData.name.$error,
                  },attrs:{"type":"text","id":"name","placeholder":_vm.$t('name')},domProps:{"value":(_vm.formData.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.submitted && _vm.$v.formData.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formData.name.required)?_c('span',[_vm._v(_vm._s(_vm.$t('name'))+_vm._s(_vm.$t('is_required')))]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"contact"}},[_vm._v(_vm._s(_vm.$t('contact'))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.formData.phone),expression:"formData.phone",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.formData.phone.$error,
                  },attrs:{"type":"tel","id":"contact","placeholder":"60xxxxxxxxxx","oninput":"this.value = this.value.replace(/[^0-9]/g, '').replace(/(\\+\\d{1,2})\\s?(\\d{3})\\s?(\\d{4})/, '$1$2$3')"},domProps:{"value":(_vm.formData.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "phone", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.submitted && _vm.$v.formData.phone.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formData.phone.required)?_c('span',[_vm._v(_vm._s(_vm.$t('phone'))+_vm._s(_vm.$t('is_required')))]):_vm._e(),(!_vm.$v.formData.phone.countryCodeAndPhone)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('phone_invalid'))+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t('email'))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.formData.email),expression:"formData.email",modifiers:{"trim":true}}],staticClass:"form-control bg-white",class:{
                    'is-invalid': _vm.submitted && _vm.$v.formData.email.$error,
                  },attrs:{"type":"email","id":"email","placeholder":_vm.$t('email')},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "email", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.submitted && _vm.$v.formData.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formData.email.required)?_c('span',[_vm._v(_vm._s(_vm.$t('email'))+_vm._s(_vm.$t('is_required')))]):_vm._e(),(!_vm.$v.formData.email.email)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('email_invalid'))+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"message"}},[_vm._v(_vm._s(_vm.$t('message')))]),_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.formData.enquiry),expression:"formData.enquiry",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.formData.enquiry.$error,
                  },attrs:{"id":"message","rows":"4","placeholder":_vm.$t('message')},domProps:{"value":(_vm.formData.enquiry)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "enquiry", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.submitted && _vm.$v.formData.enquiry.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formData.enquiry.required)?_c('span',[_vm._v(_vm._s(_vm.$t('message'))+_vm._s(_vm.$t('is_required')))]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"quest"}},[_vm._v(_vm._s(_vm.mathQuestion))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.formData.mathAnswer),expression:"formData.mathAnswer",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.formData.mathAnswer.$error,
                  },attrs:{"type":"tel","id":"quest","placeholder":_vm.$t('answer'),"oninput":"this.value = this.value.replace(/[^+-\\d]/g, '').replace(/(\\+?[\\d-]{1,2})\\s?(\\d{3})\\s?(\\d{4})/, '$1$2$3')"},domProps:{"value":(_vm.formData.mathAnswer)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "mathAnswer", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.submitted && _vm.$v.formData.mathAnswer.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formData.mathAnswer.required)?_c('span',[_vm._v(_vm._s(_vm.$t('anwser_required')))]):(!_vm.$v.formData.mathAnswer.isCorrect)?_c('span',[_vm._v(_vm._s(_vm.$t('wrong_anwser')))]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"mb-0"},[_c('button',{staticClass:"btn btn-primary fw-medium",attrs:{"disabled":_vm.loading,"type":"button"},on:{"click":_vm.submit}},[(_vm.loading)?_c('span',[_vm._v(_vm._s(_vm.$t('submitting')))]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('Submit')))]),(_vm.loading)?_c('span',{staticClass:"spinner-border spinner-border-sm ms-2",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e()])])])])])])])])])]),_c('div',{staticClass:"bg-primary"},[_c('div',{staticClass:"container d-block d-lg-none pb-2 enquiry_box"},[_c('div',{staticClass:"row align-items-center justify-content-center"},[_c('div',{staticClass:"col-12 px-4 px-md-3"},[_c('div',{staticClass:"card card-custom p-3 mb-0 text-body"},[_c('div',{staticClass:"p-3"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t('name')))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.formData.name),expression:"formData.name",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.formData.name.$error,
                  },attrs:{"type":"text","id":"name","placeholder":_vm.$t('name')},domProps:{"value":(_vm.formData.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.submitted && _vm.$v.formData.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formData.name.required)?_c('span',[_vm._v(_vm._s(_vm.$t('name'))+_vm._s(_vm.$t('is_required')))]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"contact"}},[_vm._v(_vm._s(_vm.$t('contact'))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.formData.phone),expression:"formData.phone",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.formData.phone.$error,
                  },attrs:{"type":"tel","id":"contact","placeholder":"60xxxxxxxxxx","oninput":"this.value = this.value.replace(/[^0-9]/g, '').replace(/(\\+\\d{1,2})\\s?(\\d{3})\\s?(\\d{4})/, '$1$2$3')"},domProps:{"value":(_vm.formData.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "phone", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.submitted && _vm.$v.formData.phone.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formData.phone.required)?_c('span',[_vm._v(_vm._s(_vm.$t('phone'))+_vm._s(_vm.$t('is_required')))]):_vm._e(),(!_vm.$v.formData.phone.countryCodeAndPhone)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('phone_invalid'))+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t('email'))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.formData.email),expression:"formData.email",modifiers:{"trim":true}}],staticClass:"form-control bg-white",class:{
                    'is-invalid': _vm.submitted && _vm.$v.formData.email.$error,
                  },attrs:{"type":"email","id":"email","placeholder":_vm.$t('email')},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "email", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.submitted && _vm.$v.formData.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formData.email.required)?_c('span',[_vm._v(_vm._s(_vm.$t('email'))+_vm._s(_vm.$t('is_required')))]):_vm._e(),(!_vm.$v.formData.email.email)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('email_invalid'))+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"message"}},[_vm._v(_vm._s(_vm.$t('message')))]),_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.formData.enquiry),expression:"formData.enquiry",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.formData.enquiry.$error,
                  },attrs:{"id":"message","rows":"4","placeholder":_vm.$t('message')},domProps:{"value":(_vm.formData.enquiry)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "enquiry", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.submitted && _vm.$v.formData.enquiry.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formData.enquiry.required)?_c('span',[_vm._v(_vm._s(_vm.$t('message'))+" "+_vm._s(_vm.$t('is_required')))]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"quest"}},[_vm._v(_vm._s(_vm.mathQuestion))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.formData.mathAnswer),expression:"formData.mathAnswer",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.formData.mathAnswer.$error,
                  },attrs:{"type":"tel","id":"quest","placeholder":_vm.$t('answer'),"oninput":"this.value = this.value.replace(/[^+-\\d]/g, '').replace(/(\\+?[\\d-]{1,2})\\s?(\\d{3})\\s?(\\d{4})/, '$1$2$3')"},domProps:{"value":(_vm.formData.mathAnswer)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "mathAnswer", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.submitted && _vm.$v.formData.mathAnswer.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formData.mathAnswer.required)?_c('span',[_vm._v(_vm._s(_vm.$t('anwser_required')))]):(!_vm.$v.formData.mathAnswer.isCorrect)?_c('span',[_vm._v(_vm._s(_vm.$t('wrong_anwser')))]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"mb-0"},[_c('button',{staticClass:"btn btn-primary fw-medium",attrs:{"disabled":_vm.loading,"type":"button"},on:{"click":_vm.submit}},[(_vm.loading)?_c('span',[_vm._v(_vm._s(_vm.$t('submitting')))]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('Submit')))]),(_vm.loading)?_c('span',{staticClass:"spinner-border spinner-border-sm ms-2",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e()])])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }