<template>
  <Layout>
    <Header />
    <div class="container-fluid p-0">
      <div class="banner position-relative">
        <h1 style="text-indent: -9999px; font-size:0px" class="m-0 p-0 h-0">{{title}}</h1>
        <img src="@/assets/images/app/home.jpg" :alt="title" class="img-fluid">
        <div class="position-absolute top-50 start-0 translate-middle-y w-100">
          <div class="container text-white px-3">
           
            <div class="row justify-content-end">
              <div class="col-md-8 col-10 text-end">
                <h2 class="text-white display-4 animate__animated animate__slideInRight font-style-2 mt-4 mt-md-0">{{$t('caring_for_your_health')}}</h2>
                <p class="text-end animate__animated animate__fadeInDown mb-2 mb-lg-3 d-none d-lg-block"> {{$t('caring_wording_bottom')}}</p>
                <p class="animate__animated animate__fadeInUp d-none d-lg-block"><router-link to="/about-us" class="text-white"> <i class="mdi mdi-chevron-double-left"></i>  {{$t('learn_more')}} </router-link></p>
              </div>
              <div class="col-lg-8 col-10 text-end mt-0 mt-lg-5 animate__animated animate__fadeInUp">
                <h2 class="text-white font-style-3 mb-1 name-signature"><img src="@/assets/images/app/dr-lim-signature.png" alt="Dr. Lim"></h2>
                <p class="mb-0 spec-position d-none d-lg-block">  {{$t('position')}} </p>
                <p class="mb-0 spec-position d-block d-lg-none">  {{$t('positionMobile')}} </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid p-0">
      <div class="container py-2 bannerHightlight mb-3">
          <div class="row">
            <div class="col-12">
              <div class="card card-custom p-4 font-style-2 mb-0 mx-2 mx-md-0">
                <div class="row align-items-center">
                  <div class="col-12 col-lg-3 text-md-center">
                    <div class="d-lg-inline-block d-block text-start p-2">
                      <h3 class="text-primary fw-medium mb-0" data-aos="fade-down" v-html="$t('our_expertise')">
                    </h3>
                    </div>
                   
                  </div>
                  <div class="col-6 col-lg-3">
                    <div class="p-2" data-aos="fade-down">
                    <span class="text-danger mb-2 d-block font-size-16 fw-semibold">01.</span>
                    <h4 class="mb-0">
                      {{$t('expertise01')}}
                    </h4>
                    </div>
                  </div>
                  <div class="col-6 col-lg-3">
                    <div class="p-2" data-aos="fade-down">
                    <span class="text-danger mb-2 d-block font-size-16 fw-semibold">02.</span>
                    <h4 class="mb-0">
                      {{$t('expertise02')}}
                    </h4>
                    </div>
                  </div>
                  <div class="col-12 col-lg-3">
                    <div class="p-2" data-aos="fade-down">
                    <span class="text-danger mb-2 d-block font-size-16 fw-semibold">03.</span>
                    <h4 class="mb-0">{{$t('expertise03')}}
                    </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="container mb-5 px-3">
      <div class="row align-items-center justify-content-center">
        <div class="col-12 col-lg-6" >
          <div data-aos="fade-right">
          <img src="@/assets/images/app/drlim-homepage.png"  class="img-fluid rounded animate__animated mb-4"  :alt="title"/>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="px-md-4" data-aos="fade-left">
            <h2 class="font-style-2 fw-semibold display-6 mb-2 text-primary animate__animated animate__slideInRight">{{$t('drlim')}}</h2>
            <div class="text-danger mb-4 font-size-15 animate__animated animate__slideInRight">   {{$t('position')}}</div>
            <p class="animate__animated animate__slideInRight">{{$t('about_content')}}</p>

            <router-link to="/about-us" class="animate__animated animate__slideInRight text-uppercase text-danger">{{$t('more_on_drlim')}} <i class="mdi mdi-chevron-double-right"></i></router-link>
          </div>
          
        </div>
      </div>
    </div>
      
    <Services />
    <Enquiry />
    <Contact />
    
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/main";
import appConfig from "@/app.config";
import Header from "@/views/pages/drlim/components/header";
import Services from "@/views/pages/drlim/components/services";
import Enquiry from '@/views/pages/drlim/components/enquiry';
import Contact from '@/views/pages/drlim/components/contact';
/**
 * Starter page
 */
export default{
  components: { Layout , Header, Services, Enquiry, Contact},
  page: {
    title: "",  
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  
  data() {
    return {
      title: appConfig.title,
      items: [],
    };
  },
  created(){

  },
  mounted(){
  },
  methods: {
},
}
    
</script>



<style scoped>
.bannerHightlight{
  position: relative;
  top: -40px;
}
.card-custom{
  border-radius: 20px;
  box-shadow: 2px 5px 13px rgba(0, 0, 0, 0.2);
}
.card-custom h3{
  font-size:28px;
  font-weight: 700;
}
.card-custom h4{
  color: #222;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.3;
}
.banner h2.name-signature img{
    height: 48px;
}
.spec-position{
  font-size: 14px;
}
@media (max-width: 800px){
  .spec-position{
    font-size: 11px !important;
  }
 
  .banner h2{
    font-size: 24px;
  }
  .banner p{
    font-size: 13px;
  }

  .banner h2.name-signature img{
    height: 30px;
  }
  .bannerHightlight{
    top: -20px;
  }
}
</style>